/* .profile-card {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  } */
  
  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  h2 {
    margin-bottom: 0.5rem;
  }
  
  .availability-list {
    list-style: none;
    padding-left: 1rem;
  }
  
  .availability-list li {
    margin-bottom: 0.25rem;
  }
  