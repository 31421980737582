.date-slots {
    display: flex;
    overflow-x: scroll;
    gap: 16px;
    padding: 16px 0;
    width: 100%;
  }
  
  .time-slots {
    display: flex;
    overflow-x: scroll;
    gap: 16px;
    padding: 16px 0;
    width: 100%;
  }
  
  .date-slot-card,
  .time-slot-card {
    width: 120px;
    flex-shrink: 0;
    transition: border-color 0.3s;
  }
  
  .date-slot-card.selected,
  .time-slot-card.selected {
    border-color: #1890ff;
  }
  
  .date-slot-card:hover,
  .time-slot-card:hover {
    border-color: #1890ff;
    cursor: pointer;
  }
  