@import "../../../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&family=Roboto:wght@100;300;400;500;700;900&display=swap');

$primary: #612F8A;
$secondary: #8ACCA5;

.parentContainer {
    h3 {
        text-align: center;
    }

    .container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        div {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            margin-bottom: 15px;
        }
    }

}

body {
    font-family: Roboto;
}

.backgroundPrimary {
    background-color: $primary;
}

.backgroundSecondary {
    background-color: $secondary;
}

.primary-text {
    color: $primary;
}

.btn {
    font-size: 1rem !important;
    border-radius: 12px !important;

    &.small {
        font-size: 0.75rem !important;
    }
}

.btn-primary {
    background-color: $primary;
    border-radius: 12px;
    border-color: $primary !important;

    &:hover {
        color: $primary !important;
        border-color: $primary;
        background-color: $white !important;
    }
}
.btn-secondary {
    background-color: $secondary !important;
    border-radius: 12px;
    border-color: $secondary !important;

    &:hover {
        color: $secondary !important;
        border-color: $secondary;
        background-color: $white !important;
    }
}

.primary-color {
    color: $primary !important;
}

.headertext {
    color: $primary;
}

.swiper-slide {
    height: auto;
}

.social-icon-wrpr {
    .social-icon {
        width: 24px !important;
        height: 24px !important;
    }
}

.dark-border {
    border-color: black !important;
}

.primary-boxshadow {
    box-shadow: 8px 8px 16px 0px rgba(145, 100, 182, 0.80);
}

.min-card-height {
    min-height: calc(50vw / 5);
}

.therapist-img {
    max-width: 10em;
}

.listStyling {
    .ant-spin-container {
        .ant-list-item {
            position: relative;
            padding-left: 10px;

            &::before {
                content: '';
                background-color: #000000;
                display: block;
                width: 5px;
                height: 5px;
                position: absolute;
                top: 8px;
                left: -10px;
                border-radius: 50%;

            }
        }
    }
}

.appointmentCard {
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #F5F5F5;
    box-shadow: 8px 8px 20px 0px rgba(0, 0, 0, 0.10);
}

.searchField {
    input {
        border-radius: 50px !important;
        margin: 20px 10px;
    }
    .ant-input-group-addon {
        button {
            border-radius: 50px !important;
            margin-left: 20px !important;
            margin-top: 20px;
        }
    }
}
.w-fit-content {
    width: fit-content;
}