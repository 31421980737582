
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import '~react-toastify/dist/ReactToastify.min.css';

@include media-breakpoint-down(md) {
    html,body {
        font-size: 14px;
    }
}

$primary: #612F8A;
$secondary: #8ACCA5;

body {
    font-family: Roboto;
}
.backgroundPrimary {
    background-color: $primary;
}
.primary-text {
    color: $primary;
}
.backgroundSecondary {
    background-color: $secondary;
}
.secondary-text {
    color: $secondary;
}
.btn {
    font-size: 1.5rem !important;
    &.small {
        font-size: 1rem !important;
    }
}
.btn-primary {
    background-color: $primary;
    &:hover {
        color: $primary !important;
        border-color: $primary;
        background-color: $white !important;
    }
}
.primary-color {
    color: $primary !important;
}
.headertext {
    color: $primary;
}
.swiper-slide {
    height: auto;
}
.social-icon-wrpr {
    .social-icon {
        width: 24px !important;
        height: 24px !important;
    }
}
.dark-border {
    border-color: black !important;
}
.primary-boxshadow {
    box-shadow: 8px 8px 16px 0px rgba(145, 100, 182, 0.80);
}
.min-card-height {
    height: calc(100vw / 4);
    &.therapistCard {
        height: 350px;    
    }
}
.therapist-img {
    max-width: 10em;
}
.award-list {
    .ant-list-item {
        position: relative;
        padding-left: 10px;
        &::before {
            content: '';
            background-color: #000000;
            display: block;
            width: 7px;
            height: 7px;
            position: absolute;
            left: -10px;
            top: 24px;
            border-radius: 50%;

        }
    }
}
.cloud-bg {
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center;
    padding: 6.5rem 
}
.form-check-input:checked { 
    background-color: $primary;
    border-color:  $primary;
}
.border-primary {
    border-color:  $primary !important; 
}
.text-primary {
    color: $primary !important;
}
@media screen and (min-width: 320px) {
    .swiper-container {
      width: 320px;
    }
  }
  
@media screen and (min-width: 768px) {
    .swiper-container {
      width: 768px;
    }
}